const authProvider = {
    login: ({ username, password }) => {
        const request = new Request(process.env.REACT_APP_URL_BACK+'/Token', {
            method: 'POST',
            body: `grant_type=password&username=${username}&password=${password}`,
            headers: new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(auth => {
                if (auth.isAdmin == "True") {
                    localStorage.setItem('auth', JSON.stringify(auth));
                    localStorage.setItem('permissions', auth.role);
                } else {
                    throw new Error("Vous n'êtes pas autorisé à vous connecter")
                }
                
            })
            .catch((e) => {
                throw new Error(e.message);
            });
    },
    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('auth');
            return Promise.reject({ message: false });
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    checkAuth: () => localStorage.getItem('auth') != null
        ? Promise.resolve()
        : Promise.reject(),
    getIdentity: () => {
        try {
            const { id, fullName, avatar } = JSON.parse(localStorage.getItem('auth'));
            return Promise.resolve({ id, fullName, avatar });
        } catch (error) {
            return Promise.reject(error);
        }
    },
    // authorization
    getPermissions: () => {
        const role = localStorage.getItem('permissions');
        return role ? Promise.resolve(role) : Promise.reject();
    },
    logout: () => {
        localStorage.removeItem('auth');
        localStorage.removeItem('permissions');
        return Promise.resolve();
    },
};

export default authProvider;