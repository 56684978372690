import api from "./api";



export const getDayStats = async (auth) => {
    var data= await api.get(`/api/DayStats`, {
        headers: {
            Authorization: "Bearer " + auth.access_token,
        }
    }).catch((error) => {
        console.log(error);
        return false;
    });
    if(data) return data;
}