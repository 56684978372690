import api from "./api";



export const downloadKbis = async (kbisId,auth) => {
    return await api.get(`/api/file/download?kbisId=${kbisId}`, {
        headers: {
            Authorization: "Bearer " + auth.access_token,
        },
        responseType: 'blob',
    }).catch((error) => {
        console.log(error);
        return false;
    });
}