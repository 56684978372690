// in posts.js
import React, { useEffect, useState } from 'react';
import { List, Datagrid, TextField, DateField, BooleanField, ShowButton, EmailField, Filter, TextInput, Pagination, EditButton, NumberField, FunctionField, useRefresh } from 'react-admin';
import { BotsList } from '../bots/bots-list';
import dataProvider from "../providers/dataProvider";
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import { useDataProvider } from 'react-admin';

const KbisPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

const KbisFilter = (props) => (
    <Filter {...props}>
        <TextInput label="SIREN" source="siren" alwaysOn />
        <TextInput label="Nom" source="name" alwaysOn />
        <TextInput label="Email" source="email" alwaysOn />
    </Filter>
);


const BotsAvailableForOperator = () => {
    const [bots, setBots] = useState([]);
    var getBots = async () => {
        var bots = await dataProvider.getList("BotIGs", { pagination: {}, field: {}, sort: {} });
        if (bots && bots.data) {
            var operatorBots = bots.data.filter(b => b.availableForOperator);
            setBots(operatorBots);
        }
    }
    useEffect(async () => {
        await getBots();
    }, []);
    return (
        <div className="container-accounts-infogreffe">
            <div className="contain-link-infogreffe">
                <strong>Listes des comptes utilisables pour générer un KBIS</strong>
                <a href="https://www.infogreffe.fr/login" target="_blank" className="link-infogreffe" >Lien vers SiteWeb Infogreffe</a>
            </div>
            <div className="list-accounts-infogreffe">
                {bots.map((bot, index) =>
                    <div key={index} className="account-infogreffe">
                        <div>Utilisateur : <strong>{bot.email}</strong> <br /></div>
                        <div>Mot de passe : <strong>{bot.password}</strong></div>
                    </div>
                )}
            </div>
        </div>)
}

export const KbisList = (props) => {
    const dataProvider = useDataProvider();
    const refresh = useRefresh()

    const markedAsUnavailable = (kbis) => {
        dataProvider.getCustom('SetKbisNotExist', { id: kbis.id })
            .then((data) => {
                refresh();
            })
            .catch((e) => {
                console.log(e);
            });
    }

    return (
        <>
            <BotsAvailableForOperator></BotsAvailableForOperator>
            <div><strong>KBIS non généré(s):</strong></div>
            <List {...props} pagination={<KbisPagination />} title="Kbis en erreur" actions={<></>} filters={<KbisFilter />}>
                <>
                    <Datagrid>
                        <TextField label="SIREN" source="siren" />
                        <TextField label="Nom" source="name" />
                        <EmailField label="Email" source="cvUser.email" />
                        <DateField showTime={true} label="Date de création" source="createdAt" />
                        <BooleanField label="Génération en cours" source="botsAreOnIt" />
                        <EditButton />
                        <FunctionField render={record => <Button  startIcon={<CloseIcon />} color="primary" onClick={()=>markedAsUnavailable(record)}>
                            Aucun KBIS disponible</Button>
                        } />
                    </Datagrid>
                </>
            </List>
        </>
    )
};