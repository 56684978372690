import * as React from 'react';
import { Component } from 'react';
import { Admin, Resource, Login } from 'react-admin';
import authProvider from './providers/authProviders';
import './App.css';
import { UsersList } from './users/user-list';
import { KbisList } from './kbis/kbis-list';
import jsonServerProvider from './providers/dataProvider';
import { UserShow } from './users/user-show';
import frenchMessages from 'ra-language-french';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import customRoutes from './customRoutes';
import Menu from './components/menu/menu';
import { KbisEdit } from './kbis/kbis-edit';
import addUploadFeature from './utils/addUploadFeatures';
import { BotsList } from './bots/bots-list';
import { BotCreate } from './bots/bot-create';
import { BotEdit } from './bots/bot-edit';

const i18nProvider = polyglotI18nProvider(() => frenchMessages, 'fr');

const App = () => (
  <Admin i18nProvider={i18nProvider} authProvider={authProvider} dataProvider={jsonServerProvider} customRoutes={customRoutes} menu={Menu}>
    <Resource options={{ label: 'Clients' }} name="Admin" list={UsersList} show={UserShow} />
    <Resource options={{ label: 'Kbis non généré(s)' }} name="Kbis" list={KbisList} edit={KbisEdit} />
    <Resource options={{ label: 'Bots Infogreffes', superAdmin: true }} name="BotIGs" list={BotsList} create={BotCreate} edit={BotEdit}  />
  </Admin>
)

export default App;
