import * as React from "react";
import { Edit, SimpleForm, TextInput, FileInput, ImageField } from 'react-admin';

export const KbisEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput disabled label="Id" source="id" />
            <TextInput disabled label="SIREN" source="siren" />
            <TextInput disabled label="Nom" source="name" />
            <TextInput disabled label="Email" source="cvUser.email" />
            <FileInput source="file" label="Fichier KBIS" accept=".pdf" placeholder={<p>Placer le KBIS ici</p>} multiple={false}>
                <ImageField source="src" title="title" />
            </FileInput>
        </SimpleForm>
    </Edit>
);