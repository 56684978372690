import React, { useEffect, useState } from 'react';
import { CartesianGrid, XAxis, YAxis, Tooltip, BarChart, Bar, Label, ResponsiveContainer } from 'recharts';
import { useDataProvider } from 'react-admin';

const PaymentsByDay = () => {

    const dataProvider = useDataProvider();
    const [value, setValue] = useState('');
    const [graphData, setGraphData] = useState([]);

    useEffect(() => {
        dataProvider.getCustom('Admin/stats')
            .then(({ data }) => {
                data.forEach(element => {
                    element.name = new Date(element.name).toLocaleDateString();
                });
                setGraphData(data);
            })
            .catch((e) => {
                console.log(e);
            });
    }, [value]);

    return (
        <ResponsiveContainer width='100%' height='80%'>
            <BarChart data={graphData} margin={{ top: 15, right: 30, left: 20, bottom: 20 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" >
                    <Label value="Revenues par souscription par date de souscription" offset={0} position="bottom" />
                </XAxis>
                <YAxis >
                    <Label value="€/Utilisateur" angle="-90" position="insideLeft" offset={20} startOffset={20} />
                </YAxis>
                <Tooltip />
                <Bar type="monotone" dataKey="value" fill="#0a3b70" />
            </BarChart>
        </ResponsiveContainer>
    );
}

export default PaymentsByDay