import React from 'react';
import { Page, Document, Image, StyleSheet, View } from '@react-pdf/renderer';
import InvoiceTitle from './InvoiceTitle';
import BillTo from './BillTo';
import InvoiceNo from './InvoiceNo';
import InvoiceItemsTable from './InvoiceItemsTable';
import logo from '../../Frame.png';
import InvoiceFooter from './InvoiceFooter';


const styles = StyleSheet.create({
    page: {
        fontSize: 11,
        paddingTop: 30,
        paddingLeft: 60,
        paddingRight: 60,
        lineHeight: 1.5,
        flexDirection: 'column',
        height: "100%"
    },
    logo: {
        height: 40,
        width: 123
    },
    footer: {
        bottom: 20,
    }
});

const Invoice = ({ user, payment }) => (
    <Document>
        <Page size="A4" style={styles.page}>
            <Image style={styles.logo} src={logo} />
            <View style={{ flex: 1 }}>
                {/* <InvoiceTitle title='Facture N°' /> */}
                <BillTo user={user} />
                <InvoiceNo payment={payment} />
                <InvoiceItemsTable payment={payment} />
            </View>
            <InvoiceFooter style={styles.footer} />
            {/* <InvoiceThankYouMsg /> */}
        </Page>
    </Document>
);

export default Invoice