import { React, useState, useEffect, Fragment } from "react";
import { useShowController, SimpleShowLayout, TextField, EmailField, useDataProvider } from 'react-admin';
import Snackbar from '@material-ui/core/Snackbar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import Grid from '@material-ui/core/Grid';
import { pdf } from '@react-pdf/renderer';
import Invoice from "../components/invoice/Invoice";
import Button from '@material-ui/core/Button';
import fileDownload from 'js-file-download';
import { downloadKbis } from "../services/FileService";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { TotalProcessingBill } from "../components/TotalProcessingBill";

const MyShow = props => {

    const dataProvider = useDataProvider();

    const {
        basePath, // deduced from the location, useful for action buttons
        defaultTitle, // the translated title based on the resource, e.g. 'Post #123'
        loaded, // boolean that is false until the record is available
        loading, // boolean that is true on mount, and false once the record was fetched
        record, // record fetched via dataProvider.getOne() based on the id from the location
        resource, // the resource name, deduced from the location. e.g. 'posts'
        version, // integer used by the refresh feature
    } = useShowController(props);

    const [open, setOpen] = useState(false);

    const [openDialog, setOpenDialog] = useState(false);
    const [openTpDialog, setOpenTpDialog] = useState(false);
    const [currentPaymentToConfirm, setCurrentPaymentToConfirm] = useState({});

    const [snackContent, setSnackContent] = useState('');

    const [invoices, setInvoices] = useState([]);
    const [tpInvoices, setTpInvoices] = useState([]);
    const [totalProcessingPayments, setTotalProcessingPayments] = useState([]);

    const [kbiss, setKbiss] = useState([]);

    useEffect(() => {
        var invoices = record?.invoices?.sort((a, b) => new Date(b.created) - new Date(a.created));
        setInvoices(invoices ? invoices : []);

        var kbiss = record?.kbiss?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setKbiss(kbiss ? kbiss : []);

    }, [record]);


    const [payments, setPayments] = useState([]);

    useEffect(() => {
        var payments = record?.payments?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setPayments(payments ? payments : []);
        var tpPayments = record?.totalProcessingPayments?.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
        setTotalProcessingPayments(tpPayments ? tpPayments : [])
        if (record?.id) {
            getTotalProcessingInvoices(record?.id)
        }
    }, [record]);

    const openSnackBar = (content) => {
        setSnackContent(content);
        setOpen(true);
    };

    const getTotalProcessingInvoices = (id) => {
        dataProvider.getCustom('paymenttp/admin/getinvoices/' + id)
            .then(({ data }) => {
                var tpInv = data.sort((a, b) => new Date(b.date) - new Date(a.date));
                setTpInvoices(tpInv)
            })
            .catch((e) => {
                console.log(e);
            });
    }

    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        setSnackContent("");
    };

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const downloadPDF = async (row, payment) => {
        const blob = await pdf(<Invoice user={row} payment={payment} />).toBlob();
        const file = new Blob([blob], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);

        window.open(fileURL, "_blank");
    }

    const downloadInvoice = async (fileURL) => {
        window.open(fileURL, "_blank");
    }

    const downloadTotalProcessingInvoice = (id) => {
        const html2pdf = require('html2pdf.js');

        var element = document.getElementById("tpinvoice-" + id);

        html2pdf().from(element).set({
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: {
                dpi: 192,
                scale: 5,
                letterRendering: true,
                useCORS: true
            },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            margin: [0, 0, 0, 0],
        }).toPdf().save("Facture-" + id + ".pdf");
    }

    const downloadDocument = async (kbis) => {
        const auth = JSON.parse(localStorage.getItem('auth'));
        var kbisContent = await downloadKbis(kbis.id, auth);
        fileDownload(kbisContent.data, `${kbis.name}.pdf`);
    }

    const refillPayment = (paymentItem) => {
        dataProvider.getCustom('Admin/Refund', { id: paymentItem.charges[0].id })
            .then((data) => {
                console.log(data)
                if (data.reason) {
                    openSnackBar('Erreur lors du remboursement ' + data.reason);
                } else {
                    openSnackBar('Remboursement effectué avec succès');
                }

            })
            .catch((e) => {
                console.log(e)
                openSnackBar('Erreur lors du remboursement ' + e.reason);
            });
    }

    const refillPaymentTp = (paymentItem) => {
        dataProvider.postCustom('paymenttp/admin/refillPayment', paymentItem)
            .then((data) => {
                console.log(data)
                if (data.reason) {
                    openSnackBar('Erreur lors du remboursement ' + data.reason);
                } else {
                    openSnackBar('Remboursement effectué avec succès');
                }

            })
            .catch((e) => {
                console.log(e)
                openSnackBar('Erreur lors du remboursement ' + e.reason);
            });
    }

    const confirmRefillPayment = (paymentItem) => {
        refillPayment(paymentItem);
        handleCloseDialog();
    }


    const confirmRefillPaymentTpp = (paymentItem) => {
        refillPaymentTp(paymentItem);
        handleCloseTpDialog();
    }

    const handleOpenDialog = (paymentItem) => {
        setCurrentPaymentToConfirm(paymentItem);
        setOpenDialog(true);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleOpenTpDialog = (paymentItem) => {
        setCurrentPaymentToConfirm(paymentItem);
        setOpenTpDialog(true);
    };

    const handleCloseTpDialog = () => {
        setOpenTpDialog(false);
    };



    const getSubscriptionStatus = (statusNumber) => {
        switch (statusNumber) {
            case 0:
                return "Essai";
            case 1:
                return "En cours";
            case 2:
                return "Retard de paiement";
            case 3:
                return "Annulé par opérateur";
            case 4:
                return "Annulé pour retard de paiement";
            case 5:
                return "Annulé par le client";
            default:
                return statusNumber;
        }
    }

    const revokeSubscription = () => {

        /*
        try {
            if (record.subscription != null && record.subscription.id != null) {
                dataProvider.getCustom('Admin/revoke', { id: record.subscription.id })
                    .then((data) => {
                        if (data.reason) {
                            openSnackBar('Erreur lors de la révoquation de la soucription ' + data.message);
                        } else {
                            openSnackBar('Souscription révoquée et email de confirmation envoyé au client.');
                        }
                    })
                    .catch((e) => {
                        openSnackBar('Erreur lors de la révoquation de la soucription ' + e);
    
                    });
            }
        } catch(e) {}*/

        if (record.subscriptionTotalProcessing != null && record.subscriptionTotalProcessing.id != null) {
            dataProvider.getCustom('paymenttp/admin/Revoke', { id: record.subscriptionTotalProcessing.id })
                .then((data) => {
                    if (data.reason) {
                        openSnackBar('Erreur lors de la révoquation de la soucription ' + data.message);
                    } else {
                        openSnackBar('Souscription révoquée et email de confirmation envoyé au client.');
                    }
                })
                .catch((e) => {
                    openSnackBar('Erreur lors de la révoquation de la soucription ' + e);

                });
        }
    }

    const resendPassword = () => {
        dataProvider.getCustom(`Account/ResetPassword?email=${record.email}`)
            .then(({ data }) => {
                openSnackBar(`Le nouveau mot de passe a été envoyé à ${record.email}`);
            })
            .catch((e) => {
                openSnackBar('Erreur lors de l\'envoi du nouveau mot de passe ' + e);

            });
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    function getStatusName(code) {
        switch (code) {
            case 'capture':
                return 'Payée';
            case 'completed':
                return 'Payée';
            case 'refund':
                return 'Remboursée';
            default:
                return code;
        }
    }

    function getTPStatusName(code) {
        switch (code) {
            case 'DB':
                return 'Payée';
            case 'RF':
                return 'Remboursée';
            default:
                return code;
        }
    }

    function isRefundable(payment) {
        if (payments && payments.length > 3) {
            return payment.id === payments[0].id;
        } else {
            return false
        }
    }

    return (
        <div>
            <h1>{record?.uid}</h1>

            <h2>Détails de l'abonnement</h2>
            <Grid container direction="row">

                <Grid item xs={6} container direction="column">
                    <Grid item >
                        Client : {record?.email}
                    </Grid>
                    <Grid item>
                        Crée le : {record && record.createdAt ? new Date(record.createdAt).toLocaleString() : ''}
                    </Grid>
                    {/*
                    <Grid item>
                        Période actuelle : {' du ' + new Date(record?.subscription?.beginAt).toLocaleDateString() + ' au ' + new Date(record?.subscription?.dateNextWithdrawalMonth).toLocaleDateString()}
                    </Grid>*/}
                    <Grid item>
                        ID : {record?.id}
                    </Grid>
                    <Grid item>
                        Statut : <strong>{record?.subscriptionTotalProcessing != null ? getSubscriptionStatus(record?.subscriptionTotalProcessing?.status) : getSubscriptionStatus(record?.subscription?.status)}</strong>
                    </Grid>
                    {record?.subscriptionTotalProcessing != null && !(record?.subscriptionTotalProcessing?.status.startsWith("cancel")) &&
                        <Grid item>
                            Montant de la souscription : {record?.subscriptionTotalProcessing?.amount}
                        </Grid>
                    }
                    <br />
                    <br />
                    <Grid item>
                        Origin : {record?.origin}
                    </Grid>
                    <Grid item>
                        Referrer : {record?.referrer}
                    </Grid>
                    <Grid item>
                        IP : {record?.ip}
                    </Grid>
                </Grid>

                <Grid item>
                    {record?.subscriptionTotalProcessing != null && !(record?.subscriptionTotalProcessing?.status.startsWith("cancel")) ?
                        <Button variant="contained" color="primary" onClick={revokeSubscription}>
                            Révoquer l'abonnement
                        </Button>
                        : <></>
                    }
                    <Button variant="contained" color="primary" style={{ marginLeft: "15px" }} onClick={resendPassword}>
                        Renvoyer mot de passe
                    </Button>
                </Grid>

            </Grid>

            {
                kbiss && kbiss.length > 0 && <>
                    <h2>Documents</h2>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">SIREN</TableCell>
                                    <TableCell align="center">Nom</TableCell>
                                    <TableCell align="center">Date de création</TableCell>
                                    <TableCell align="center">Heure de création</TableCell>
                                    <TableCell align="center">Document</TableCell>
                                    <TableCell align="center">Type de document</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {kbiss.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell align="center">{row.siren} </TableCell>
                                        <TableCell align="center">{row.name}</TableCell>
                                        <TableCell align="center">{row.createdAt ? new Date(row.createdAt).toLocaleString() : ''}</TableCell>
                                        <TableCell align="center">{row.createdAt ? new Date(row.createdAt).toLocaleTimeString() : ''}</TableCell>
                                        <TableCell align="center">
                                            {
                                                !row.available && !row.notExist ? <>
                                                    <i>En cours de création</i>
                                                </> : [
                                                    row.notExist ? <> Ni KBIS ni INSEE doc n'existe </> :
                                                        <IconButton color="primary" component="span" onClick={() => downloadDocument(row)} >
                                                            <GetAppIcon />
                                                        </IconButton>
                                                ]
                                            }
                                        </TableCell>
                                        <TableCell align="center">
                                            {
                                                row.notExist ? '' :
                                                    [
                                                        row.isInsee ? "Doc INSEE" : 'KBIS'
                                                    ]
                                            }
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                </>
            }
            <h2>Factures</h2>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Montant</TableCell>
                            <TableCell align="center">Statut</TableCell>
                            <TableCell align="center">Numéro de facture</TableCell>
                            <TableCell align="center">Date du paiement</TableCell>
                            <TableCell align="center"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tpInvoices.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell align="center">{row.amount} €</TableCell>
                                <TableCell align="center">Paid</TableCell>
                                <TableCell align="center">{row.invoiceNumber}</TableCell>
                                <TableCell align="center">{row.date ? new Date(row.date).toLocaleString() : ''}</TableCell>
                                <TableCell align="center">
                                    <IconButton color="primary" component="span" onClick={() => downloadTotalProcessingInvoice(row.invoiceNumber)} >
                                        <GetAppIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                        {invoices.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell align="center">{row.amount_paid / 100} €</TableCell>
                                <TableCell align="center">{getStatusName(row.status)}</TableCell>
                                <TableCell align="center">{row.number}</TableCell>
                                <TableCell align="center">{row.created ? new Date(row.created * 1000).toLocaleString() : ''}</TableCell>
                                <TableCell align="center">
                                    <IconButton color="primary" component="span" onClick={() => downloadInvoice(row.invoice_pdf)} >
                                        <GetAppIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <h2>Paiements</h2>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Montant</TableCell>
                            <TableCell align="center">Statut</TableCell>
                            <TableCell align="center">Référence du paiement</TableCell>
                            <TableCell align="center">Date du paiement</TableCell>
                            <TableCell align="center"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {
                                totalProcessingPayments.map((paymentItem, index) => (
                                    <TableRow key={index}>
                                        <TableCell align="center">{paymentItem.amount} €</TableCell>
                                        <TableCell align="center">{getTPStatusName(paymentItem.paymentType)}</TableCell>
                                        <TableCell align="center">{paymentItem.paymentType == "RF" ? paymentItem.referencedId : paymentItem.id}</TableCell>
                                        <TableCell align="center">{paymentItem.timestamp ? new Date(paymentItem.timestamp).toLocaleString() : ''}</TableCell>
                                        <TableCell align="center">
                                            {paymentItem.paymentType != "RF" &&
                                                <>
                                                    <Button color="primary" onClick={() => handleOpenTpDialog(paymentItem)}>Rembourser</Button>
                                                    <Dialog
                                                        open={openTpDialog}
                                                        onClose={handleCloseTpDialog}
                                                        aria-labelledby="alert-dialog-title"
                                                        aria-describedby={"alert-dialog-description-" + paymentItem.id}
                                                    >
                                                        <DialogTitle id="alert-dialog-title">Remboursement</DialogTitle>
                                                        <DialogContent>
                                                            <DialogContentText id={"alert-dialog-description-" + paymentItem.id}>
                                                                Êtes vous sur d'effectuer un remboursement de {currentPaymentToConfirm.amount} €
                                                            </DialogContentText>
                                                        </DialogContent>
                                                        <DialogActions>
                                                            <Button onClick={handleCloseTpDialog} color="primary">
                                                                Annuler
                                                            </Button>
                                                            <Button onClick={() => confirmRefillPaymentTpp(currentPaymentToConfirm)} color="primary" autoFocus>
                                                                Rembourser
                                                            </Button>
                                                        </DialogActions>
                                                    </Dialog>
                                                </>}
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </>
                        {payments.map((paymentItem, index) => (
                            <TableRow key={index}>
                                <TableCell align="center">{paymentItem.amount / 100} €</TableCell>
                                <TableCell align="center">{getStatusName(paymentItem.status)}</TableCell>
                                <TableCell align="center">{paymentItem.transaction_reference}</TableCell>
                                <TableCell align="center">{paymentItem.createdAt ? new Date(paymentItem.createdAt).toLocaleString() : ''}</TableCell>
                                <TableCell align="center">
                                    {paymentItem.status == "succeeded" && paymentItem.charges[0].refunded == false ?
                                        <>
                                            <Button color="primary" onClick={() => handleOpenDialog(paymentItem)}>Rembourser</Button>
                                            <Dialog
                                                open={openDialog}
                                                onClose={handleCloseDialog}
                                                aria-labelledby="alert-dialog-title"
                                                aria-describedby="alert-dialog-description"
                                            >
                                                <DialogTitle id="alert-dialog-title">Remboursement</DialogTitle>
                                                <DialogContent>
                                                    <DialogContentText id="alert-dialog-description">
                                                        Êtes vous sur d'effectuer un remboursement de {currentPaymentToConfirm.amount / 100} €
                                                    </DialogContentText>
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button onClick={handleCloseDialog} color="primary">
                                                        Annuler
                                                    </Button>
                                                    <Button onClick={() => confirmRefillPayment(currentPaymentToConfirm)} color="primary" autoFocus>
                                                        Rembourser
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>
                                        </>
                                        : <></>}
                                </TableCell>
                            </TableRow>
                        ))}

                    </TableBody>
                </Table>
            </TableContainer>
            <Box style={{ display: "none" }}>
                {tpInvoices.map((row, index) => (
                    <Box style={{}}>
                        <TotalProcessingBill invoice={row} />
                    </Box>
                ))
                }
            </Box>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleCloseSnackBar} message={snackContent} autoHideDuration={6000} anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }} action={
                <Fragment>
                    <Button color="secondary" size="small" onClick={handleCloseSnackBar}>
                        OK
                    </Button>
                </Fragment>
            } />
        </div>
    );
}

export const UserShow = (props) => (
    <MyShow {...props}>
        <SimpleShowLayout>
            <TextField source="uid" />
            <TextField source="name" />
            <EmailField source="email" />
        </SimpleShowLayout>
    </MyShow>
);