import React, { useEffect, useState } from 'react';
import { CartesianGrid, XAxis, YAxis, Tooltip, BarChart, LineChart, Bar, Line, Legend, Label, ResponsiveContainer } from 'recharts';
import { useDataProvider } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';


const ActiveClientGraph = () => {

    const dataProvider = useDataProvider();
    const [value, setValue] = useState('');
    const [graphData, setGraphData] = useState([]);

    useEffect(() => {
        refreshGraph(30);
    }, [value]);


    const refreshGraph = (days) => {
        const subscriptionPromise = dataProvider.getCustom('Admin/ActivesClients/Stats', { days });
        Promise.all([subscriptionPromise]).then((data) => {
            const subscriptions = data[0].data;

            const finalArray = subscriptions;
            finalArray.sort((a,b) => {
                return new Date(a.name) - new Date(b.name);
            }); 
            finalArray.forEach(element => {
                element.name = new Date(element.name).toLocaleDateString();
            });
            setGraphData(subscriptions);
        })
            .catch((e) => {
                console.log(e);
            });
    };

    return (
        <>
            <div style={{ width: "100%", height: "100%" }}>
                <Grid container direction="row" justify="flex-start" alignItems="center">
                    <Button variant="contained" color="primary" onClick={() => refreshGraph(7)} >
                        7j
                </Button>
                    <Button variant="contained" color="primary" onClick={() => refreshGraph(30)} style={{ marginLeft: "15px" }}>
                        30 derniers jours
                </Button>
                    <Button variant="contained" color="primary" onClick={() => refreshGraph(90)} style={{ marginLeft: "15px" }}>
                        90 derniers jours
                </Button>
                </Grid>
                <ResponsiveContainer width='100%' height='80%' style={{ top: "15px" }}>
                    <LineChart data={graphData} margin={{ top: 15, right: 30, left: 20, bottom: 20 }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" >
                            <Label value="Date" offset={0} position="bottom" />
                        </XAxis>
                        <YAxis >
                            <Label value="Nombre de clients actifs" angle="-90" position="insideLeft" offset={20} startOffset={20} />
                        </YAxis>
                        <Tooltip />
                        <Legend verticalAlign="top" height={36} />
                        <Line name="Nombre de clients actifs" type="monotone" dataKey="sub" stroke="#02DD03" />
                    </LineChart >
                </ResponsiveContainer>
            </div>
        </>
    );
}

export default ActiveClientGraph