import { stringify } from 'query-string';
import { fetchUtils } from 'ra-core';
import { convertFileToBase64 } from '../utils/addUploadFeatures';

const jsonServerProvider = (apiUrl, httpClient = fetchUtils.fetchJson) => ({
    getList: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            ...fetchUtils.flattenObject(params.filter),
            sort: field,
            order: order,
            start: (page - 1) * perPage,
            take: perPage,
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then((data) => {
            const { headers, json } = data;
            if (!headers.has('x-total-count')) {
                throw new Error(
                    'The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?'
                );
            }
            return {
                data: json,
                total: parseInt(
                    headers.get('x-total-count').split('/').pop(),
                    10
                ),
            };
        });
    },

    getOne: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
            data: json,
        })),
    getCustom: (resource, params) => {

        if (params && params.id) {
            return httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
                data: json,
            }));
        } else if (params) {
            const query = {
                days: params.days,
            };
            return httpClient(`${apiUrl}/${resource}?${stringify(query)}`).then(({ json }) => ({
                data: json,
            }));
        }
        return httpClient(`${apiUrl}/${resource}`).then(({ json }) => ({
            data: json,
        }));
    },

    postCustom: (resource, data) =>
        httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(data),
        }).then(({ json }) => ({
            data: json,
        })),

    getMany: (resource, params) => {
        const query = {
            id: params.ids,
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url).then(({ json }) => ({ data: json }));
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            ...fetchUtils.flattenObject(params.filter),
            [params.target]: params.id,
            _sort: field,
            _order: order,
            _start: (page - 1) * perPage,
            _end: page * perPage,
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => {
            // if (!headers.has('x-total-count')) {
            //     throw new Error(
            //         'The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?'
            //     );
            // }
            return {
                data: json,
                total: json.length
                // parseInt(
                //     headers.get('x-total-count').split('/').pop(),
                //     10
                // ),
            };
        });
    },

    update: async (resource, params) => {
        if (resource !== 'Kbis' || !params.data.file) {
            return httpClient(`${apiUrl}/${resource}/${params.id}`, {
                method: 'PUT',
                body: JSON.stringify(params.data),
            }).then(({ json }) => ({ data: json }));
        } else {
            const myFile = params.data.file;
            if (!myFile.rawFile instanceof File) {
                return Promise.reject('Error: Not a file...'); // Didn't test this...
            }
            const base64File = await convertFileToBase64(myFile);
            const dataFile = {
                src: base64File,
                title: `${myFile.title}`
            }
            const data = {
                ...params.data,
                file: dataFile
            }
            return httpClient(`${apiUrl}/${resource}/${params.id}`, {
                method: 'PUT',
                body: JSON.stringify(data),
            }).then(({ json }) => ({ data: json }));
        }

    },

    // json-server doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
    updateMany: (resource, params) =>
        Promise.all(
            params.ids.map(id =>
                httpClient(`${apiUrl}/${resource}/${id}`, {
                    method: 'PUT',
                    body: JSON.stringify(params.data),
                })
            )
        ).then(responses => ({ data: responses.map(({ json }) => json.id) })),

    create: (resource, params) =>
        httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({
            data: { ...params.data, id: json.id },
        })),

    delete: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: json })),

    // json-server doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    deleteMany: (resource, params) =>
        Promise.all(
            params.ids.map(id =>
                httpClient(`${apiUrl}/${resource}/${id}`, {
                    method: 'DELETE',
                })
            )
        ).then(responses => ({ data: responses.map(({ json }) => json.id) })),
});


const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const auth = JSON.parse(localStorage.getItem('auth'));
    const token = auth && auth.access_token ? auth.access_token : '';
    options.headers.set('Authorization', `Bearer ${token}`);
    options.headers.set('Access-Control-Expose-Headers', 'x-total-count');
    return fetchUtils.fetchJson(url, options);
}

export default jsonServerProvider(process.env.REACT_APP_URL_BACK + "/api/", httpClient);