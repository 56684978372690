import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#1B1464'
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: '#1B1464',
        backgroundColor: '#1B1464',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
    },
    description: {
        width: '44%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        color: "white",
        paddingRight: 8
    },
    qty: {
        width: '9%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        color: "white",
        paddingRight: 8
    },
    rate: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        color: "white",
        textAlign: 'center',
        paddingRight: 8
    },
    amount: {
        borderRightColor: borderColor,
        borderRightWidth: 1,
        color: "white",
        textAlign: 'center',
        paddingRight: 8,
        width: '20%'
    },
    total: {
        width: '12%',
        color: "white",
        paddingRight: 8,
        textAlign: 'center',
    },
  });

  const InvoiceTableHeader = () => (
    <View style={styles.container}>
        <Text style={styles.description}>Description</Text>
        <Text style={styles.qty}>Qté</Text>
        <Text style={styles.rate}>Total HT</Text>
        <Text style={styles.amount}>Montant/Taux TVA</Text>
        <Text style={styles.total}>Total TTC</Text>
    </View>
  );
  
  export default InvoiceTableHeader