import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
   
    titleContainer:{
        flexDirection: 'column',
        marginTop: 12,
        bottom: 0
    },
    reportTitle:{
        fontSize: 9,
        textAlign: 'center',
    }
  });


  const InvoiceFooter = () => (
    <View style={styles.titleContainer}>
        <Text style={styles.reportTitle}>Digital Moonwalk Ltd, 13047005.</Text>
        <Text style={styles.reportTitle}>167 -169 Great Portland Street, 5th Floor, London, United Kingdom, W1W 5PE</Text>
        <Text style={styles.reportTitle}>VAT GB 365112518</Text>
    </View>
  );
  
  export default InvoiceFooter