import * as React from 'react';
import { createElement } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { MenuItemLink, getResources, usePermissions } from 'react-admin';
import { withRouter } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

const Menu = ({ onMenuClick, logout }) => {
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    const permissions = usePermissions();
    const resources = useSelector(getResources);
    const resourcesOperator = resources.filter(r => !r.options?.superAdmin);
    const resourcesSuperAdmin = resources.filter(r => r.options?.superAdmin);
    return (
        <div>
            <Typography style={{ marginLeft: "15px", marginTop: "20px", marginBottom: "20px", fontWeight: "bold" }}>Opérateurs</Typography>
            {resourcesOperator.map(resource => (
                <>
                    <MenuItemLink
                        key={resource.name}
                        to={`/${resource.name}`}
                        primaryText={resource.options && resource.options.label || resource.name}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        style={{ paddingLeft: "35px" }}
                    />
                    <Divider />
                </>
            ))}
            {permissions.permissions === "SUPERADMIN" &&
                <>
                    <div style={{ height: "30vh", width: "5px" }}></div>
                    <Typography style={{ marginLeft: "15px", marginTop: "20px", marginBottom: "20px", fontWeight: "bold" }}>Administrateurs</Typography>
                    <Divider />
                    {resourcesSuperAdmin.map(resource => (
                        <>
                            <MenuItemLink
                                key={resource.name}
                                to={`/${resource.name}`}
                                primaryText={resource.options && resource.options.label || resource.name}
                                onClick={onMenuClick}
                                sidebarIsOpen={open}
                                style={{ paddingLeft: "35px" }}
                            />
                            <Divider />
                        </>
                    ))}
                    {/* 
                    <MenuItemLink
                        to="/ltv-clients"
                        primaryText="LTV des clients"
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        style={{ paddingLeft: "35px"}}
                        key="ltv"
                    />
                    <MenuItemLink
                        to="/monthly-payments-transactions"
                        primaryText="Mensualités"
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        style={{ paddingLeft: "35px"}}
                        key="month"
                    />*/
                        <>
                            <MenuItemLink
                                to="/subscriptions"
                                primaryText="Souscriptions"
                                onClick={onMenuClick}
                                sidebarIsOpen={open}
                                style={{ paddingLeft: "35px" }}
                                key="subscriptions"
                            />
                            <Divider />
                        </>}

                    <MenuItemLink
                        to="/actives-clients"
                        primaryText="Clients actifs"
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        style={{ paddingLeft: "35px" }}
                        key="actives-clients"
                    />
                </>
            }
            {isXSmall && logout}
        </div>
    );
}

export default withRouter(Menu);