import React, {Fragment} from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#1B14643'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#1B1464',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 30,
        fontStyle: 'bold',
    },
    description: {
        width: '44%',
        textAlign: 'left',
        borderRightColor: borderColor,
        paddingLeft: 8,
        lineHeight: "30px",
        display: "flex",
        alignItems: "center"
    },
    qty: {
        width: '9%',
        borderRightColor: borderColor,
        textAlign: 'center',
        paddingRight: 8,
        lineHeight: "30px",
        display: "flex",
        alignItems: "center"
    },
    rate: {
        width: '15%',
        borderRightColor: borderColor,
        textAlign: 'center',
        paddingRight: 8,
        lineHeight: "30px",
        display: "flex",
        alignItems: "center"
    },
    amount: {
        borderRightColor: borderColor,
        width: '20%',
        textAlign: 'center',
        paddingRight: 8,
        lineHeight: "30px",
        display: "flex",
        alignItems: "center"
    },
    total: {
        width: '12%',
        textAlign: 'center',
        paddingRight: 8,
        lineHeight: "30px",
        display: "flex",
        alignItems: "center"
    },
  });


const InvoiceTableRow = ({items}) => {
    const rows = items.map( item => 
        <View style={styles.row} key={item.id}>
            <Text style={styles.description}>RDS - Accès illimité à la plateforme</Text>
            <Text style={styles.qty}>1</Text>
            <Text style={styles.rate}>{(item.amount/100 /1.2).toFixed(2)}</Text>
            <Text style={styles.amount}>{(item.amount/100 - item.amount/100 /1.2).toFixed(2)} (20%)</Text>
            <Text style={styles.total}>{item.amount/100} €</Text>
        </View>
    )
    return (<Fragment>{rows}</Fragment> )
};
  
export default InvoiceTableRow