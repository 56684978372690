import moment from "moment";
import { useEffect } from "react";
import 'moment/locale/fr';
import { Grid, Typography } from "@material-ui/core";


export const TotalProcessingBill = (props) => {

    const invoice = props.invoice;
    const invoiceVersion = props.invoice.invoiceVersion;

    useEffect(() => {
        moment.locale('fr');
    }, []);

    const getDisplayDate = (date) => {
        return moment(date).format("DD MMMM YYYY");
    }

    const getShortDate = (date) => {
        return moment(date).format("DD MMM YYYY");
    }

    const getLastDayNextMonth = (date) => {
        var currDate = new Date(date);
        var lastDay = new Date(currDate.setMonth(currDate.getMonth() + 1));
        return getShortDate(lastDay);
    }

    return (
        <Grid container
            id={"tpinvoice-" + invoice.invoiceNumber}
            style={{
                width: "20.9cm",
                height: "29.6cm",
                backgroundColor: "#FFFFFF",
                padding: "40px",
                color: "black",
            }}
            direction="column">
            <Grid item style={{ height: "100%" }}>
                <Grid container direction="column" style={{ position: "relative", width: "100%", height: "100%" }}>
                    {/* Header */}
                    <Grid item style={{ width: "100%" }}>
                        <Grid container direction="row" justifyContent="space-between" style={{ justifyContent: "space-between" }}>
                            <Grid item>
                                <SemiBoldInter style={{ fontSize: "18pt" }}>
                                    Facture
                                </SemiBoldInter>
                            </Grid>
                            <Grid item>
                                <SemiBoldInter style={{ fontSize: "18pt", color: "#808080" }}>
                                    {invoiceVersion.nameCompany}
                                </SemiBoldInter>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* Bill informations */}
                    <Grid item style={{ width: "100%" }}>
                        <Grid container direction="row" style={{ marginTop: "25px", width: "45%" }}>
                            <Grid item>
                                <Grid container direction="column" justifyContent="space-between">
                                    <Grid item>
                                        <SemiBoldInter>
                                            Numéro de facture
                                        </SemiBoldInter>
                                    </Grid>
                                    <Grid item>
                                        <SemiBoldInter>
                                            Date d&apos;émission
                                        </SemiBoldInter>
                                    </Grid>
                                    <Grid item>
                                        <SemiBoldInter>
                                            Date d&apos;échéance
                                        </SemiBoldInter>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item style={{ marginLeft: "12px" }}>
                                <Grid container direction="column">
                                    <Grid item>
                                        <SemiBoldInter>
                                            {invoice.invoiceNumber}
                                        </SemiBoldInter>
                                    </Grid>
                                    <Grid item>
                                        <SemiBoldInter>
                                            {getDisplayDate(invoice.date)}
                                        </SemiBoldInter>
                                    </Grid>
                                    <Grid item>
                                        <SemiBoldInter>
                                            {getDisplayDate(invoice.date)}
                                        </SemiBoldInter>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* Company informations */}
                    <Grid item style={{ width: "100%" }}>
                        <Grid container direction="row" style={{ marginTop: "25px" }}>
                            <Grid item xs={6} >
                                <Grid container direction="column" >
                                    <Grid item>
                                        <SemiBoldInter>
                                            {invoiceVersion.nameCompany}
                                        </SemiBoldInter>
                                    </Grid>
                                    <Grid item>
                                        <NormalInter>
                                            {invoiceVersion.phoneCompany}
                                        </NormalInter>
                                    </Grid>
                                    <Grid item>
                                        <NormalInter>
                                            {invoiceVersion.emailCompany}
                                        </NormalInter>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container direction="column" >
                                    <Grid item>
                                        <SemiBoldInter>
                                            Facturer à
                                        </SemiBoldInter>
                                    </Grid>
                                    <Grid item>
                                        <NormalInter>
                                            {invoice.emailClient}
                                        </NormalInter>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* Paiement header */}
                    <Grid item style={{ width: "100%" }}>
                        <Grid container direction="column" style={{ marginTop: "25px" }}>
                            <Grid item>
                                <SemiBoldInter style={{ fontSize: "13pt" }}>
                                    {invoice.amount} € dus le {getDisplayDate(invoice.date)}
                                </SemiBoldInter>
                            </Grid>
                            <Grid item>
                                <NormalInter lineHeight="14pt" style={{ marginTop: "12px" }}>
                                    {invoiceVersion.nameCompany}
                                </NormalInter>
                            </Grid>
                            <Grid item>
                                <NormalInter lineHeight="14pt">
                                    {invoiceVersion.licenceNumberCompany} <br />
                                    {invoiceVersion.addressCompany} &nbsp;{invoiceVersion.cityCompany} <br />
                                    {invoiceVersion.addressComplementCompany}

                                </NormalInter>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* Table */}
                    <Grid item style={{ width: "100%" }}>
                        <Grid container direction="column" style={{ width: "100%", marginTop: "25px" }}>
                            <Grid item style={{ width: "100%" }}>
                                <Grid container direction="row" style={{ width: "100%", borderBottom: "1px solid #000000", width: "100%", paddingBottom: "2px" }}>
                                    <Grid item style={{ width: "50%" }}>
                                        <NormalInter style={{ fontSize: "7pt", lineHeight: "16pt" }}>
                                            Description
                                        </NormalInter>
                                    </Grid>
                                    <Grid item style={{ width: "15%" }}>
                                        <NormalInter style={{ fontSize: "7pt", lineHeight: "16pt" }}>
                                            Qté
                                        </NormalInter>
                                    </Grid>
                                    <Grid item style={{ width: "25%" }}>
                                        <NormalInter style={{ fontSize: "7pt", lineHeight: "16pt" }}>
                                            Prix unitaire
                                        </NormalInter>
                                    </Grid>
                                    <Grid item style={{ width: "10%" }}>
                                        <NormalInter style={{ fontSize: "7pt", lineHeight: "16pt", textAlign: "right" }}>
                                            Montant
                                        </NormalInter>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction="row" style={{ width: "100%", marginTop: "3px" }}>
                                    <Grid item style={{ width: "50%" }}>
                                        <Grid container direction="column">
                                            <Grid item>
                                                <SemiBoldInter style={{ fontSize: "9pt", lineHeight: "18pt" }}>
                                                    {invoice.description}
                                                </SemiBoldInter>
                                            </Grid>
                                            {
                                                parseInt(invoice.amount) > 5 &&
                                                <Grid item>
                                                    <NormalInter style={{ fontSize: "9pt", lineHeight: "18pt" }}>
                                                        {getShortDate(invoice.date)} - {getLastDayNextMonth(invoice.date)}
                                                    </NormalInter>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid item style={{ width: "15%" }}>
                                        <NormalInter style={{ fontSize: "9pt", lineHeight: "18pt" }}>
                                            1
                                        </NormalInter>
                                    </Grid>
                                    <Grid item style={{ width: "25%" }}>
                                        <NormalInter style={{ fontSize: "9pt", lineHeight: "18pt" }}>
                                            {invoice.amount} €
                                        </NormalInter>
                                    </Grid>
                                    <Grid item style={{ width: "10%" }}>
                                        <NormalInter style={{ fontSize: "9pt", lineHeight: "18pt", textAlign: "right" }}>
                                            {invoice.amount} €
                                        </NormalInter>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* Table resume */}
                    <Grid item style={{ width: "100%", marginTop: "25px" }}>
                        <Grid container direction="row" justifyContent="flex-end" style={{ justifyContent: "flex-end" }}>
                            <Grid item xs={6} >
                                <Grid container direction="column" style={{ width: "100%" }}>
                                    <Grid item>
                                        <Grid container direction="row" style={{ borderTop: "1px solid lightgrey", padding: "8px 0px", justifyContent: "space-between" }} justifyContent="space-between">
                                            <Grid item>
                                                <NormalInter>
                                                    Sous-total
                                                </NormalInter>
                                            </Grid>
                                            <Grid item>
                                                <NormalInter>
                                                    {invoice.amount} €
                                                </NormalInter>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="row" style={{ borderTop: "1px solid lightgrey", padding: "8px 0px", justifyContent: "space-between" }} justifyContent="space-between">
                                            <Grid item>
                                                <NormalInter>
                                                    Total
                                                </NormalInter>
                                            </Grid>
                                            <Grid item>
                                                <NormalInter>
                                                    {invoice.amount} €
                                                </NormalInter>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="row" style={{ borderTop: "1px solid lightgrey", padding: "8px 0px", justifyContent: "space-between" }} justifyContent="space-between">
                                            <Grid item>
                                                <SemiBoldInter style={{ fontSize: "9pt" }}>
                                                    Montant dû
                                                </SemiBoldInter>
                                            </Grid>
                                            <Grid item>
                                                <SemiBoldInter style={{ fontSize: "9pt" }}>
                                                    {invoice.amount} €
                                                </SemiBoldInter>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* Informations */}
                    <Grid item style={{ width: "100%", marginTop: "25px" }}>
                        <NormalInter style={{ lineHeight: "14.4pt", textAlign: "justify" }}>
                            {invoiceVersion.additionnalInformation1} <br />
                            {invoiceVersion.additionnalInformation2} <br />
                            {invoiceVersion.additionnalInformation3} <br />
                        </NormalInter>
                    </Grid>
                    {/* Footer */}
                    <Grid item style={{ width: "100%" }}>
                        <Grid container direction="row" justifyContent="space-between" style={{ width: "100%", justifyContent: "space-between", borderTop: "1px solid lightgrey", position: "absolute", bottom: "0px", padding: "20px 0px 0px" }}>
                            <Grid item>
                                <NormalInter style={{ fontSize: "7pt", lineHeight: "11.5pt" }}>
                                    {invoice.invoiceNumber}
                                </NormalInter>
                            </Grid>
                            <Grid item>
                                <NormalInter style={{ fontSize: "7pt", lineHeight: "11.5pt" }}>
                                    Page 1 sur 1
                                </NormalInter>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid >
    );
}

const SemiBoldInter = (props) => {

    var { style, ...others } = { ...props };

    return (
        <Typography
            style={{
                fontWeight: style?.fontWeight ? style.fontWeight : 600,
                fontSize: style?.fontSize ? style.fontSize : "9pt",
                fontFamily: "Inter",
                color: style?.color ? style.color : "#000000",
                lineHeight: style?.lineHeight ? style.lineHeight : "14pt"
            }}
            {...others}>
            {props.children}
        </Typography >
    )
}

const NormalInter = (props) => {

    var { style, ...others } = { ...props };

    return (
        <Typography
            style={{
                fontWeight: style?.fontWeight ? style.fontWeight : 400,
                fontSize: style?.fontSize ? style.fontSize : "9pt",
                fontFamily: "Inter",
                color: style?.color ? style.color : "#000000",
                lineHeight: style?.lineHeight ? style.lineHeight : "14.4pt"
            }} {...others}>
            {props.children}
        </Typography >
    )
}