// in posts.js
import * as React from "react";
import { List, Datagrid, TextField, DateField, BooleanField, ShowButton, EmailField, Filter, TextInput, Pagination, NumberField, EditButton, DeleteButton } from 'react-admin';



export const BotsList = (props) => (
    <List {...props}  title="Bots Infogreffe" >
        <>
            <Datagrid>
                <EmailField label="Email" source="email" />
                <TextField label="Mot de passe" source="password" />
                <NumberField label="Montant disponible" source="moneyAmount" />
                <BooleanField label="Running" source="busy" />
                <BooleanField label="Disponible pour opérateur" source="availableForOperator" />
                <EditButton />
                <DeleteButton />
            </Datagrid>
        </>
    </List>
);