import * as React from "react";
import { Route } from 'react-router-dom';
import ActiveClientGraph from "./pages/activeClientGraph";
import MonthlyPaymentsTransactions from "./pages/monthlyPaymentsTransactions";
import PaymentsByDay from './pages/paymentsByDay';
import SubscriptionsGraph from './pages/subscriptionsGraph';


export default [
    <Route exact path="/ltv-clients" component={PaymentsByDay} />,
    <Route exact path="/monthly-payments-transactions" component={MonthlyPaymentsTransactions} />,
    <Route exact path="/subscriptions" component={SubscriptionsGraph} />,
    <Route exact path="/actives-clients" component={ActiveClientGraph} />,
];