// in posts.js
import * as React from "react";
import { List, Datagrid, TextField, DateField, BooleanField, ShowButton, EmailField, Filter, TextInput, Pagination } from 'react-admin';
import {
    Grid
} from '@material-ui/core';

const UserPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;
import { getDayStats } from "../services/StatsService";

const UsersFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Identifiant" source="uid" alwaysOn />
        <TextInput label="Email" source="email" alwaysOn />
        <TextInput label="Société" source="society" alwaysOn />
    </Filter>
);

const UserInformationsForAdmin = (props) => {
    const [dayStats, setDayStats] = React.useState([]);
    var getDayStas = async () => {
        const auth = JSON.parse(localStorage.getItem('auth'));
        var dayStats = await getDayStats(auth);
        if(dayStats.data) setDayStats(dayStats.data);
    }
    React.useEffect(async () => {
        await getDayStas();
    }, []);


    return <div>
        <Grid container justify="flex-end">    
            <Grid item className="day-data-container">
                <Grid container direction="column">
                    <Grid item><strong>Données du jour :</strong></Grid>
                    <Grid item> Souscriptions : <strong>{dayStats.subscriptionsCount}</strong></Grid>
                    <Grid item> KBIS générés : <strong>{dayStats.kbissCount}</strong></Grid>
                    <Grid item> Fiche INSEE générées : <strong>{dayStats.inseesCount}</strong></Grid>

                </Grid>
            </Grid>
                 
        </Grid>
    </div>
}

export const UsersList = (props) => {
    
    return (<>
        
        {
            props.permissions === "SUPERADMIN" && <UserInformationsForAdmin />
        }

        <List {...props} pagination={<UserPagination />} title="Clients" actions={<></>} filters={<UsersFilter />} sort={{ field: 'createdAt', order: 'ASC' }} >
            <>
                <Datagrid>
                    <TextField label="Identifiant" source="uid" />
                    <EmailField label="Email" source="email" />
                    <TextField label="Société" source="societyName" />
                    <TextField label="Mid" source="midName" />
                    <DateField showTime={true} label="Date de création" source="createdAt" />
                    <BooleanField label="Souscription" source="isSubscribed" sortable={false} />
                    <TextField label="Nombre de Kbis" source="kbisCount" sortable={false} />
                    <ShowButton />
                </Datagrid>
            </>
        </List>
    </>)
};