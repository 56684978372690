import React, { useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

const MonthlyPaymentsTransactions = () => {

    const dataProvider = useDataProvider();
    const [value, setValue] = useState('');
    const [data, setData] = useState([]);

    useEffect(() => {
        dataProvider.getCustom('Admin/validatedTransactionStats')
            .then(({ data }) => {
                setData(data);
            })
            .catch((e) => {
                console.log(e);
            });
    }, [value]);

    return (
        <Grid container direction="row" justify="center" alignItems="center">
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Mensualité</TableCell>
                            <TableCell align="center">Try</TableCell>
                            <TableCell align="center">Nb transactions validées</TableCell>
                            <TableCell align="center">% transactions validées</TableCell>
                            <TableCell align="center">% souscriptions chargées</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((d) => (
                            <TableRow key={d.monthlyPaymentCount}>
                                <TableCell component="th" scope="row" align="center">
                                    <strong>{d.monthlyPaymentCount}</strong>
                                </TableCell>
                                <TableCell align="center">
                                    Try 1
                                    <Divider></Divider>
                                    Try n
                                </TableCell>
                                <TableCell align="center">
                                    {d.validatedTransactionCountTry1}
                                    <Divider></Divider>
                                    {d.validatedTransactionCountTryN} 
                                </TableCell>
                                <TableCell align="center">
                                    {d.percentValidatedTransactionTry1} %
                                    <Divider></Divider>
                                    {d.percentValidatedTransactionTryN} %
                                </TableCell>
                                <TableCell align="center">
                                    {d.percentSubscriptionsCharges} %
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    );
}

export default MonthlyPaymentsTransactions