import * as React from "react";
import { Edit, SimpleForm, TextInput, FileInput, ImageField, Create, NumberInput, BooleanInput } from 'react-admin';

export const BotCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput  label="Email" source="email" />
            <TextInput  label="Mot de passe" source="password" />
            <NumberInput  label="Montant disponible" source="moneyAmount" />
            <BooleanInput label="Running" source="busy" />
            <BooleanInput label="Disponible pour opérateur" source="availableForOperator" />
        </SimpleForm>
    </Create>
);